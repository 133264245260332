import { reds } from '../constants/colors';
import { LITEMOL_STYLES, PYMOL_SCRIPT_TYPES } from '../constants/defs';
import { downloadBlobURL } from './download';

const NUM_COLORS = reds.length;

const chainExclusion = (chains) => {
  if (chains.length > 0) {
    return `and not (${chains
      .map((chain) => {
        return `chain ${chain}`;
      })
      .join(' or ')})`;
  } else {
    return '';
  }
};

export function mutationHeatmapToPymolScript({
  filename,
  activeProtein,
  pdbId,
  proteinStyle,
  mutations,
  mutationColorField,
  activeAssembly,
  entities,
  selectIndividualMutations,
  selectAllMutations,
  includeDomains,
  baseColor,
  ignoreColor,
}) {
  let assemblyCmd = '';
  if (activeAssembly !== '' && activeAssembly !== 'asym') {
    assemblyCmd = `cmd.set('assembly', ${activeAssembly})`;
  }

  let proteinStyleCmd = '';
  if (proteinStyle === LITEMOL_STYLES.SURFACE) {
    proteinStyleCmd += 'cmd.hide("cartoon")\ncmd.show("surface")';
  }

  // Get chains to hide
  const hideChains = [];
  entities.forEach((entity) => {
    if (!entity.checked) {
      hideChains.push(...entity.chains);
    }
  });

  let individualMutationSelections = '';
  if (selectIndividualMutations) {
    mutations.forEach((mut) => {
      individualMutationSelections += `cmd.select("${
        mut.ref
      }${mut.pos.toString()}${
        mut.alt
      }", "resi ${mut.pos.toString()} ${chainExclusion(hideChains)}")\n`;
    });
  }

  let allMutationsSelection = '';
  if (selectAllMutations) {
    allMutationsSelection = `cmd.select("all_mutations", "(resi ${mutations
      .map((mut) => mut.pos.toString())
      .join(' or resi ')}) ${chainExclusion(hideChains)}")\n`;
  }

  let domainSelection = '';
  if (includeDomains) {
    activeProtein.domains.forEach((domain) => {
      const domainSelectionName = domain.name
        .toLowerCase()
        .replaceAll(/\s/g, '_');
      const domainResiSelection = domain.ranges
        .map((range) => `${range[0]}-${range[1]}`)
        .join(' or resi ');

      domainSelection += `cmd.select("${domainSelectionName}", "resi ${domainResiSelection} ${chainExclusion(
        hideChains
      )}")\n`;
    });
  }

  let colorCode = '';
  mutations.forEach((mut) => {
    const colorInd = Math.floor(mut[mutationColorField] * (NUM_COLORS - 1));
    // PyMOL needs colors in "0xRRGGBB" instead of "#RRGGBB"
    colorCode += `cmd.color("0x${reds[colorInd].substring(1)}", "resi ${
      mut.pos
    } ${chainExclusion(hideChains)}")\n`;
  });

  // Hide entities
  let hideEntityCode = '';
  entities.forEach((entity) => {
    if (!entity.checked) {
      entity.chains.forEach((chain) => {
        hideEntityCode += `cmd.color("0x${ignoreColor.substring(
          1
        )}", "chain ${chain}")\n`;
      });
    }
  });

  let script = `#!/usr/bin/env python3
# coding: utf-8

## AUTO-GENERATED BY COVIDCG.ORG
## FILENAME: ${filename}
## PROTEIN: ${activeProtein.name}

from pymol import cmd, stored

pdb_name = "${pdbId}"

cmd.delete("all")

${assemblyCmd}

cmd.fetch(pdb_name, name=pdb_name)

# Get rid of water/hydrogens in the structure
cmd.remove("solvent")
cmd.remove("hydrogens")
# Clean up the rest... remove all non-protein atoms (PyMOL 2.1+ only)
# cmd.remove("(not polymer.protein)")

${proteinStyleCmd}

# Default color
cmd.color("0x${baseColor.substr(1)}", "polymer.protein")

# Create selections
${individualMutationSelections}
${allMutationsSelection}
${domainSelection}

# RESI COLORING CODE
${colorCode}

# HIDE ENTITY CODE
${hideEntityCode}
`;

  return script;
}

export function mutationHeatmapToPymolCommands({
  activeProtein,
  pdbId,
  proteinStyle,
  mutations,
  mutationColorField,
  activeAssembly,
  entities,
  selectIndividualMutations,
  selectAllMutations,
  includeDomains,
  baseColor,
  ignoreColor,
}) {
  let assemblyCmd = '';
  if (activeAssembly !== '' && activeAssembly !== 'asym') {
    assemblyCmd = `set assembly, ${activeAssembly}`;
  }

  let proteinStyleCmd = '';
  if (proteinStyle === LITEMOL_STYLES.SURFACE) {
    proteinStyleCmd += 'as surface';
  }

  // Get chains to hide
  const hideChains = [];
  entities.forEach((entity) => {
    if (!entity.checked) {
      hideChains.push(...entity.chains);
    }
  });

  let selectIndividualMutationsCmd = '';
  if (selectIndividualMutations) {
    mutations.forEach((mut) => {
      selectIndividualMutationsCmd += `select ${mut.ref}${mut.pos.toString()}${
        mut.alt
      }, resi ${mut.pos.toString()} ${chainExclusion(hideChains)}\n`;
    });
  }

  let selectAllMutationsCmd = '';
  if (selectAllMutations) {
    selectAllMutationsCmd += `select all_mutations, resi ${mutations
      .map((mut) => mut.pos.toString())
      .join(' or resi ')} ${chainExclusion(hideChains)}\n`;
  }

  let includeDomainsCmd = '';
  if (includeDomains) {
    activeProtein.domains.forEach((domain) => {
      const domainSelectionName = domain.name
        .toLowerCase()
        .replaceAll(/\s/g, '_');
      const domainResiSelection = domain.ranges
        .map((range) => `${range[0]}-${range[1]}`)
        .join(' or resi ');
      includeDomainsCmd += `select ${domainSelectionName}, resi ${domainResiSelection} ${chainExclusion(
        hideChains
      )}\n`;
    });
  }

  let colorMutationsCmd = '';
  mutations.forEach((mut) => {
    const colorInd = Math.floor(mut[mutationColorField] * (NUM_COLORS - 1));
    // PyMOL needs colors in "0xRRGGBB" instead of "#RRGGBB"
    colorMutationsCmd += `color 0x${reds[colorInd].substring(1)}, resi ${
      mut.pos
    } ${chainExclusion(hideChains)}\n`;
  });

  // Hide entities
  let hideEntityCmd = '';
  entities.forEach((entity) => {
    if (!entity.checked) {
      entity.chains.forEach((chain) => {
        hideEntityCmd += `color 0x${ignoreColor.substring(
          1
        )}, chain ${chain}\n`;
      });
    }
  });

  const commands = `
delete all

${assemblyCmd}

fetch ${pdbId}

remove solvent
remove hydrogens

${proteinStyleCmd}

color 0x${baseColor.substr(1)}, polymer.protein

${selectIndividualMutationsCmd}

${selectAllMutationsCmd}

${includeDomainsCmd}

${colorMutationsCmd}

${hideEntityCmd}
`;

  return commands;
}

export const savePymolScript = ({
  opts,
  filename,
  activeProtein,
  pdbId,
  proteinStyle,
  assemblies,
  activeAssembly,
  entities,
  mutations,
  mutationColorField,
}) => {
  let script;
  if (opts.scriptType === PYMOL_SCRIPT_TYPES.COMMANDS) {
    script = mutationHeatmapToPymolCommands({
      activeProtein,
      pdbId,
      proteinStyle,
      assemblies,
      activeAssembly,
      entities,
      mutations,
      mutationColorField,
      ...opts,
    });
  } else if (opts.scriptType === PYMOL_SCRIPT_TYPES.SCRIPT) {
    script = mutationHeatmapToPymolScript({
      filename,
      activeProtein,
      pdbId,
      proteinStyle,
      assemblies,
      activeAssembly,
      entities,
      mutations,
      mutationColorField,
      ...opts,
    });
  }
  const blob = new Blob([script]);
  const url = URL.createObjectURL(blob);
  downloadBlobURL(url, filename);
};
